<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <router-link to="/presets">
          <button class="btn btn-lightpx-5" style="margin: 20px auto">
            Back
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <preset-form
              :preset="currentPreset"
              :orgId="currentOrg.id"
              :customFonts="currentOrg.customFonts"
              @addNewPreset="submitNewPreset"
              @updatePreset="updatePreset"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PresetForm from "@/components/Preset/PresetForm";
import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
const Preset = require("@/components/Preset/Preset.json");
const UUID = require("uuid");

export default {
  name: "VbPresetDetail",
  components: {
    PresetForm,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const presetId = route.params.id;
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const currentOrg = reactive({});
    Object.assign(currentOrg, user.value.currentOrg);
    const currentPreset = reactive({});
    if (presetId === "new") {
      var newPreset = Preset;
      newPreset.identifier = UUID.v4();
      let year = new Date().getUTCFullYear();
      newPreset.disclaimer.value =
        "©Copyright " + year + ". All rights reserved.";
      Object.assign(currentPreset, newPreset);
    } else {
      const matchingPreset = user.value.currentOrg.presets.find(
        (preset) => preset.id === presetId
      );
      Object.assign(currentPreset, matchingPreset);
    }
    watch(user.value, () => {
      Object.assign(currentOrg, user.value.currentOrg);
    });
    return {
      currentPreset,
      store,
      currentOrg,
      router,
      user,
    };
  },
  mounted() {},
  methods: {
    submitNewPreset(newPreset) {
      this.store
        .dispatch("user/ADD_PRESET", {
          newPreset: newPreset,
          orgId: this.currentOrg.id,
        })
        .then(() => {
          this.router.push("/presets");
        });
    },
    updatePreset(updateData) {
      this.store
        .dispatch("user/UPDATE_PRESET", {
          updatedPreset: updateData.newPreset,
          orgId: this.currentOrg.id,
          presetId: updateData.presetId,
        })
        .then(() => {
          this.router.push("/presets");
        });
    },
  },
};
</script>
